import React,{useState, useEffect} from "react";
import Global from '../../APIConfig';
import { useNavigate,Link } from "react-router-dom";
import AuthUser from "../../AuthUser";
import axios from 'axios';

function MasterLeftPanel()
{
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const[show,SetShow]=useState('close');
    const items = JSON.parse(localStorage.getItem('user'));
    const user_privilage_string = items.user_privilage_array;
    const user_privilage_array = user_privilage_string.split(',').map(Number);
    const [companyname, setCompanyname] = useState('');
    const [loading, setloading] = useState(false);
    useEffect(()=>{
      systemdefaultdata();
    });
    const systemdefaultdata = () =>
      {
        axios({
          method: 'post',
          url: Global.baseUrl+'SiteconfigData',
          data: {
            fliedname:"company_name"
          }
        })
        .then(function (response) {
          if(response.data.status == 200)
          {
            setCompanyname(response.data.value);
          }
          else
          {
            setloading(false);
            setCompanyname('');
          }
        })
        .catch(function (error) {
          setloading(false);
          console.log(error);
        });
      }
    const userlogout=()=>{
      logout();
      navigate('/');
    }
    return (
        <div className={`sidebar ${show}`}>
          <div className="logo-details">
            <div className="head_icon" onClick={()=>{SetShow(show=='close'?'show':'close')}}>
                <i className="bx bx-menu"></i>
            </div>
            <span className="logo_name">{companyname}</span>
          </div>
          <ul class="nav-links">
          <li>
              <div class="iocn-link">
                <a href="#">
                  <i class="bx bx-grid-alt"></i>
                  <span class="link_name">Dashboard</span>
                </a>
                <i class="bx bxs-chevron-down arrow"></i>
              </div>
              <ul class="sub-menu">
                <li><a class="link_name" href="#">Dashboard</a></li>
                <li><a href="/Admin-Dash">Master Dashboard</a></li>
              </ul>
            </li>
            {items.usertype =='AD' ?
            <li>
              <div class="iocn-link">
                <a href="#">
                  <i class="bx bx-dollar"></i>
                  <span class="link_name">Price Master</span>
                </a>
                <i class="bx bxs-chevron-down arrow"></i>
              </div>
              <ul class="sub-menu">
                <li><a class="link_name" href="#">Price Master</a></li>
                <li><a href="/ServiceList/All">All Services</a></li>
                <li><a href="/ServiceList/Domestic">Domestic Services</a></li>
                <li><a href="/ServiceList/International">International Services </a></li>
                {/* <li><a href=".php">Other Charges Master</a></li> */}
              </ul>
            </li>
            :
            ""
            }
            {items.usertype =='AD' || user_privilage_array.includes(16) ?
            <li>
              <div class="iocn-link">
                <a href="#">
                  <i class="bx bx-map-alt"></i>
                  <span class="link_name">Zones</span>
                </a>
                <i class="bx bxs-chevron-down arrow"></i>
              </div>
              <ul class="sub-menu ">
                <li><a class="link_name" href="#">Shipping Zones Management</a></li>
                <li><a class="" href="/zoneList">All Zones</a></li>
                <li><a class="" href="/ZoneSetList/Domestic">Domestic Zones Cities</a></li>
                <li><a class="" href="/ZoneSetList/International">International Zones Countries</a></li>
                <li><a class="" href="/ZoneSetDomestic">Set Domestic City Zones</a></li>
                <li><a class="" href="/ZoneSetInternational">Set International Country Zones</a></li>
              </ul>
            </li>
            :
            ""
            }
            {items.usertype =='AD' || items.usertype =='M' ||user_privilage_array.includes(17) ?
            <li>
              <div class="iocn-link">
                <a href="#">
                  <i class="bx bx-flag"></i>
                  <span class="link_name">Routes</span>
                </a>
                <i class="bx bxs-chevron-down arrow"></i>
              </div>
              <ul class="sub-menu">
                <li><a class="link_name" href="#">Shipping Routes Management</a></li>
                <li><a href="/Routelist">All Routes</a></li>
                <li><a href="/RouteAdd">Add New Route</a></li>
              </ul>
            </li>
            :
            ""
            }
            {items.usertype =='AD' || user_privilage_array.includes(18) ?
            <li>
              <div class="iocn-link">
                <a href="#">
                  <i class="bx bx-map"></i>
                  <span class="link_name">Location Management</span>
                </a>
                <i class="bx bxs-chevron-down arrow"></i>
              </div>
              <ul class="sub-menu">
                <li><a class="link_name" href="#">Location Management</a></li>
                <li><a href="/Countrylist">All Countries</a></li>
                <li><a href="/CountryAdd">Add Country</a></li>
              </ul>
            </li>
            :
            ""
            }
            {items.usertype =='AD' || user_privilage_array.includes(19) ?
            <li>
              <div class="iocn-link">
                <a href="#">
                  <i class="bx bx-map-pin"></i>
                  <span class="link_name">Pincode Management</span>
                </a>
                <i class="bx bxs-chevron-down arrow"></i>
              </div>
              <ul class="sub-menu">
                <li><a class="link_name" href="#">Pincode Management</a></li>
                <li><a href="/Pincodelist">All Pincodes</a></li>
                <li><a href="/ImportPincode">Import Pincodes</a></li>
              </ul>
            </li>
            :
            ""
            }
            {items.usertype =='AD' || user_privilage_array.includes(14) ?
            <li>
              <div class="iocn-link">
                <a href="#">
                  <i class="bx bx-cog"></i>
                  <span class="link_name">Settings</span>
                </a>
                <i class="bx bxs-chevron-down arrow"></i>
              </div>
              <ul class="sub-menu" style={{"top": "-100px;"}}>
                <li><a class="link_name" href="/Settings">Settings</a></li>
              </ul>
            </li>
            :
            ""
            }
            <li>
            <div className="profile-details">
                <div className="profile-content">
                <img src={`${Global.logoUrl}${items.profile_image}`} alt="profileImg" />
                </div>
                <div className="name-job">
                <div className="profile_name">{items.name}</div>
                <div className="job">{items.company}</div>
                </div>
                <i className="bx bx-log-out" onClick={()=>userlogout()}></i>
            </div>
            </li>
      </ul>
    </div>
    );

}
export default MasterLeftPanel;