import React,{useState, useEffect} from "react";
import Global from '../../APIConfig';
import { useNavigate,Link } from "react-router-dom";
import AuthUser from "../../AuthUser";
import axios from 'axios';

function LeftPanel()
{
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const[show,SetShow]=useState('close');
    const items = JSON.parse(localStorage.getItem('user'));
    const user_privilage_string = items.user_privilage_array;
    const user_privilage_array = user_privilage_string.split(',').map(Number);
    const [companyname, setCompanyname] = useState('');
    const [loading, setloading] = useState(false);

    useEffect(()=>{
      systemdefaultdata();
    });
    const systemdefaultdata = () =>
      {
        axios({
          method: 'post',
          url: Global.baseUrl+'SiteconfigData',
          data: {
            fliedname:"company_name"
          }
        })
        .then(function (response) {
          if(response.data.status == 200)
          {
            setCompanyname(response.data.value);
          }
          else
          {
            setloading(false);
            setCompanyname('');
          }
        })
        .catch(function (error) {
          setloading(false);
          console.log(error);
        });
      }
    const userlogout=()=>{
      logout();
      navigate('/');
    }
    return (
        <div className={`sidebar ${show}`}>
          <div className="logo-details">
            <div className="head_icon" onClick={()=>{SetShow(show=='close'?'show':'close')}}>
                <i className="bx bx-menu"></i>
            </div>
            <span className="logo_name">{companyname}</span>
          </div>
          <ul className="nav-links">
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-grid-alt"></i>
                  <span className="link_name">Dashboard</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="/Admin-Dash">Dashboard</a></li>
                <li><a href="/Admin-Dash">Master Dashboard</a></li>
                <li><a href="/OperationalDashBoard">Operational Dashboard</a></li>
                <li><a href="/CustomerDashBoard">Customer Dashboard</a></li>
                <li><a href="/FinanceDashBoard">Finance Dashboard</a></li>
                <li><a href="/CmsDashBoard">CMS Dashboard</a></li>
                <li><a href="/SystemSettingDashBoard">System Setting Dashboard</a></li>
              </ul>
            </li>
            {items.usertype =='AD' || items.usertype =='M' || user_privilage_array.includes(1) ?
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-package"></i>
                  <span className="link_name">Shipments</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="#">Shipments</a></li>
                <li><a href="/Shipmentlist/All">All Shipments</a></li>
                <li><a href="/Shipmentlist/B">Undelivered Shipments</a></li>
                <li><a href="/Shipmentlist/D">Delivered Shipments</a></li>
                {/* <li><a href="shipments.php?status=ndr">Missing Shipments</a></li> */}
                {/* <li><a href="shipments.php?status=ndr">NDR Shipments</a></li> */}
                {/* <li><a href="/Shipmentlist/R">RTO Shipments</a></li> */}
              </ul>
            </li>
            :
            ""
            }
            {items.usertype =='AD' || items.usertype =='M' || user_privilage_array.includes(2) ?
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-collection"></i>
                  <span className="link_name">PRS </span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu ">
                <li><a className="link_name" href="#">Pickup (PRS) Management</a></li>
                <li><a className="" href="/PickupList/Running">Running PRS</a></li>
                <li><a className="" href="/PickupList/Completed">Completed PRS</a></li>
              </ul>
            </li>
            :
            ""
            }
            {items.usertype =='AD' || items.usertype =='M' || user_privilage_array.includes(3) ?
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-time"></i>
                  <span className="link_name">Scheduled PRS </span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu ">
                <li><a className="link_name" href="#">Scheduled Pickup Management</a></li>
                <li><a className="" href="/ScheduledPickupList/All">All Scheduled Pickups</a></li>
                {/* <li><a className="" href=".php">Scheduled PRS Customer</a></li> */}
                {/* <li><a className="" href=".php">Scheduled PRS Agent</a></li> */}
              </ul>
            </li>
            :
            ""
            }
            {items.usertype =='AD' || items.usertype =='M' || user_privilage_array.includes(4) ?
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-cuboid"></i>
                  <span className="link_name">Bagging</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="#">Bag Management</a></li>
                <li><a href="/BagList/Running">Running Bags</a></li>
                <li><a href="/BagList/Completed">Completed Bags</a></li>
              </ul>
            </li>
            :
            ""
            }
            {items.usertype =='AD' || items.usertype =='M' || user_privilage_array.includes(5) ?
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-cube-alt"></i>
                  <span className="link_name">Bag Manifest</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="#">Bag Manifest Management</a></li>
                <li><a href="/BagManifestList/Running">Running Manifests</a></li>
                <li><a href="/BagManifestList/Completed">Completed Manifests</a></li>
              </ul>
            </li>
            :
            ""
            }
            {items.usertype =='AD' || items.usertype =='M' || user_privilage_array.includes(6) ?
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-cube-alt"></i>
                  <span className="link_name">Shipment Manifest</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="#">Shipment Manifest Management</a></li>
                <li><a href="/ShipmentManifestList/Running">Running Manifests</a></li>
                <li><a href="/ShipmentManifestList/Completed">Completed Manifests</a></li>
              </ul>
            </li>
            :
            ""
            }
            {items.usertype =='AD' || items.usertype =='M' || user_privilage_array.includes(7) ?
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-run"></i>
                  <span className="link_name">DRS</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="#">DRS Management</a></li>
                <li><a href="/DrsList/Running">Running DRS</a></li>
                <li><a href="/DrsList/Completed">Completed DRS</a></li>
              </ul>
            </li>
            :
            ""
            }
            {items.usertype =='AD' || items.usertype =='M' || user_privilage_array.includes(8) ?
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-scan"></i>
                  <span className="link_name">Warehouse Scanner</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="#">Warehouse Inbound Scanner</a></li>
                <li><a className="" href="/WarehouseScanner/Pickup">Scan Pickup Shpiments</a></li>
                <li><a className="" href="/WarehouseScanner/Manifested_Bag">Scan Bags</a></li>
                <li><a className="" href="/WarehouseScanner/Manifested_Shipment">Scan Manifest Shipments</a></li>
                {/* <li><a className="" href="/WarehouseScanner/Delivery">Scan DRS Shipments</a></li> */}
              </ul>
            </li>
            :
            ""
            }
            {items.usertype =='AD' || items.usertype =='M' || user_privilage_array.includes(14) ?
            <li>
              <div className="iocn-link">
                <a href="#">
                  <i className="bx bx-cog"></i>
                  <span className="link_name">Settings</span>
                </a>
                <i className="bx bxs-chevron-down arrow"></i>
              </div>
              <ul className="sub-menu">
                <li><a className="link_name" href="/Settings">Settings</a></li>
                {/* <li><a href=".php">Channel Integration</a></li> */}
                {/* <li><a href=".php">Lable Settings</a></li> */}
                {/* <li><a href=".php">Developer APIs</a></li> */}
              </ul>
            </li>
            :
            ""
            }

            <li>
            <div className="profile-details">
                <div className="profile-content">
                <img src={`${Global.logoUrl}${items.profile_image}`} alt="profileImg" />
                </div>
                <div className="name-job">
                <div className="profile_name">{items.name}</div>
                <div className="job">{items.company}</div>
                </div>
                <i className="bx bx-log-out" onClick={()=>userlogout()}></i>
            </div>
        </li>
      </ul>
    </div>
    );

}
export default LeftPanel;