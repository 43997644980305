import React, { useState, useEffect } from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import UserLeftPanel from "../include/UserLeftPanel";
import axios from "axios";
import Global from "../../APIConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams,Link } from "react-router-dom";
import MainLoader from "../Loaders/MainLoader";
import AuthUser from "../../AuthUser";


function HubPayout(){
    let navigate = useNavigate();
    const {UniqueId} = useParams();
    const{headers1,headers2,logout,getDefaultCurrency,getDays}=AuthUser();
    const [Loading, setLoading] = useState(true);
    const [allpickuppadtransaction, setAllpickuppadtransaction] = useState([]);
    const [allpickupunpadtransaction, setAllpickupunpadtransaction] = useState([]);
    const [allmanifestpadtransaction, setAllmanifestpadtransaction] = useState([]);
    const [allmanifestunpadtransaction, setAllmanifestunpadtransaction] = useState([]);
    const [alldeliveryunpadtransaction, setAlldeliveryunpadtransaction] = useState([]);
    const [alldeliverypadtransaction, setAlldeliverypadtransaction] = useState([]);
    const [manifestpad, setManifestpad] = useState('0.00');
    const [manifestunpad, setManifestunpad] = useState('0.00');
    const [method, setMethod] = useState('Cash');
    const [manifestMethod, setManifestMethod] = useState('Cash');
    const [deliveryMethod, setDeliveryMethod] = useState('Cash');
    const [RefrenceNo, setRefrenceNo] = useState('');
    const [Remark, setRemark] = useState('');
    const [manifestRefrenceNo, setManifestRefrenceNo] = useState('');
    const [manifestRemark, setManifestRemark] = useState('');
    const [deliveryRefrenceNo, setDeliveryRefrenceNo] = useState('');
    const [deliveryRemark, setDeliveryRemark] = useState('');
    const [pickuppad, setPickuppad] = useState('0.00');
    const [pickupunpad, setPickupunpad] = useState('0.00');
    const [pickupcheckedItems, setPickupcheckedItems] = useState([]);
    const [manifestcheckedItems, setManifestcheckedItems] = useState([]);
    const [deliverycheckedItems, setDeliverycheckedItems] = useState([]);
    const [deliverypad, setDeliverypad] = useState('0.00');
    const [deliveryunpad, setDeliveryunpad] = useState('0.00');
    useEffect(()=>{
        getHubpickuppadlist();
        getHubpickupunpadlist();
        getpickuppadtotalblance();
        getpickupunpadtotalblance();
        getHubmanifestpadlist();
        getHubmanifestunpadlist();
        getmanifestpadtotalblance();
        getmanifestunpadtotalblance();
        getdeliveryunpadtotalblance();
        getdeliverypadtotalblance();
        getHubdeliverypadlist();
        getHubdeliveryunpadlist();
    },[]);
    const getHubpickuppadlist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchHubpickuppadlist',
            headers:headers1,
            data: {
                hubid:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setAllpickuppadtransaction(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const getHubpickupunpadlist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchHubpickupunpadlist',
            headers:headers1,
            data: {
                hubid:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setAllpickupunpadtransaction(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const getpickuppadtotalblance=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'pickuppadtotalblance',
            headers:headers1,
            data: {
                hubid:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setPickuppad(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const getpickupunpadtotalblance=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'pickupunpadtotalblance',
            headers:headers1,
            data: {
                hubid:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setPickupunpad(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const getHubmanifestpadlist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchHubmanifestpadlist',
            headers:headers1,
            data: {
                hubid:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setAllmanifestpadtransaction(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const getHubmanifestunpadlist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchHubmanifestunpadlist',
            headers:headers1,
            data: {
                hubid:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setAllmanifestunpadtransaction(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const getmanifestpadtotalblance=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'manifestpadtotalblance',
            headers:headers1,
            data: {
                hubid:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setManifestpad(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const getmanifestunpadtotalblance=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'manifestunpadtotalblance',
            headers:headers1,
            data: {
                hubid:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setManifestunpad(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const getHubdeliverypadlist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchHubdeliverypadlist',
            headers:headers1,
            data: {
                hubid:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setAlldeliverypadtransaction(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const getHubdeliveryunpadlist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'FetchHubdeliveryunpadlist',
            headers:headers1,
            data: {
                hubid:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setAlldeliveryunpadtransaction(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const getdeliverypadtotalblance=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'deliverypadtotalblance',
            headers:headers1,
            data: {
                hubid:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setDeliverypad(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    const getdeliveryunpadtotalblance=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'deliveryunpadtotalblance',
            headers:headers1,
            data: {
                hubid:UniqueId
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setDeliveryunpad(data);
            }
            setLoading(false);
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            setLoading(false);
            // console.log(error);
          });
    }
    //pickup selction
    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const allSlipNos = allpickupunpadtransaction.map(item => item.slip_no);
            setPickupcheckedItems(allSlipNos);
        } else {
            setPickupcheckedItems([]);
        }
    };
    const isChecked = (slip_no) => pickupcheckedItems.includes(slip_no);

    const handleChange = (event) => {
        const slipno = event.target.value;
        if(!pickupcheckedItems.includes(slipno)){
            setPickupcheckedItems([...pickupcheckedItems,slipno]);
        }else{
            let arr=pickupcheckedItems.filter((item)=>item!==slipno);
            setPickupcheckedItems(arr);
        }
        
      };
    
    //Manifest selection
    const handlemanifestSelectAllChange = (event) => {
        if (event.target.checked) {
            const allSlipNos = allmanifestunpadtransaction.map(item => item.slip_no);
            setManifestcheckedItems(allSlipNos);
        } else {
            setManifestcheckedItems([]);
        }
    };
    const manifestisChecked = (slip_no) => manifestcheckedItems.includes(slip_no);

    const handlemanifestChange = (event) => {
        const slipno = event.target.value;
        if(!manifestcheckedItems.includes(slipno)){
            setManifestcheckedItems([...manifestcheckedItems,slipno]);
        }else{
            let arr=manifestcheckedItems.filter((item)=>item!==slipno);
            setManifestcheckedItems(arr);
        }
        
      };

      //delivery selection
      const handledeliverySelectAllChange = (event) => {
        if (event.target.checked) {
            const allSlipNos = alldeliveryunpadtransaction.map(item => item.slip_no);
            setDeliverycheckedItems(allSlipNos);
        } else {
            setDeliverycheckedItems([]);
        }
    };
    const deliveryisChecked = (slip_no) => deliverycheckedItems.includes(slip_no);

    const handledeliveryChange = (event) => {
        const slipno = event.target.value;
        if(!deliverycheckedItems.includes(slipno)){
            setDeliverycheckedItems([...deliverycheckedItems,slipno]);
        }else{
            let arr=deliverycheckedItems.filter((item)=>item!==slipno);
            setDeliverycheckedItems(arr);
        }
        
      };

      const handleSubmit =(val)=>{
        if(val ==='Pickup')
        {
            axios({
                method: 'post',
                url: Global.baseUrl+'payoutsubmit',
                headers:headers1,
                data: {
                    hubid:UniqueId,
                    slip_no: pickupcheckedItems,
                    payment_mode:method,
                    reference_number:RefrenceNo,
                    remarks: Remark,
                    type:val
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                }
                else
                {
                    toast.warn(response.data.message);
                }
                
                setLoading(false);
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                setLoading(false);
                // console.log(error);
              });
        }
        else if(val ==='Manifest')
        {
            axios({
                method: 'post',
                url: Global.baseUrl+'payoutsubmit',
                headers:headers1,
                data: {
                    hubid:UniqueId,
                    slip_no: manifestcheckedItems,
                    payment_mode:manifestMethod,
                    reference_number:manifestRefrenceNo,
                    remarks: manifestRemark,
                    type:val
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                }
                else
                {
                    toast.warn(response.data.message);
                }
                setLoading(false);
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                setLoading(false);
                // console.log(error);
              });
        }
        else
        {
            axios({
                method: 'post',
                url: Global.baseUrl+'payoutsubmit',
                headers:headers1,
                data: {
                    hubid:UniqueId,
                    slip_no: deliverycheckedItems,
                    payment_mode:deliveryMethod,
                    reference_number:deliveryRefrenceNo,
                    remarks: deliveryRemark,
                    type:val
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                }
                else
                {
                    toast.warn(response.data.message);
                }
                setLoading(false);
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                setLoading(false);
                // console.log(error);
              });
        }
        
      }


    return(
        <>
        <UserLeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true?<MainLoader/>
            :
            <section className="">
                <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div class="d-block mb-4 mb-md-0">
                        <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                            <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                <li class="breadcrumb-item">
                                    <a href="/Admin-Dash">
                                    <i class="bx bx-home text-primary-400 fs-6"></i>
                                    </a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Hub Payout</li>
                            </ol>
                        </nav>
                        <div class="d-flex justify-content-between">
                            <div class="">
                                <button class="btn btn_nav btn-sm" type="button">
                                <i class="bx bx-arrow-back "></i>
                                </button>
                            </div>
                            <div class="ps-2 ">
                                <h2 class="h4">Hub Payout Center</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-12 customer_box_ei">
                        <div className="customer_top_boxx mb-3">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="card">
                                    <div className="card-body">
                                    <h2><i className="bx bx-shield"></i> Pickup Balance</h2>
                                    <h3><small>{getDefaultCurrency()} </small>{parseFloat(pickupunpad).toFixed(2)}</h3>
                                    <h6><small>Pad Balance:</small> <small>{getDefaultCurrency()} </small>{parseFloat(pickuppad).toFixed(2)}</h6>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h2><i className="bx bx-wallet"></i> Manifest Balance</h2>
                                            <h3><small>{getDefaultCurrency()} </small>{parseFloat(manifestunpad).toFixed(2)}</h3>
                                            <h6><small>Pad Balance:</small> <small>{getDefaultCurrency()} </small>{parseFloat(manifestpad).toFixed(2)}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h2><i className="bx bx-wallet"></i> Delivery Balance</h2>
                                            <h3><small>{getDefaultCurrency()} </small>{parseFloat(deliveryunpad).toFixed(2)}</h3>
                                            <h6><small>Pad Balance:</small> <small>{getDefaultCurrency()} </small>{parseFloat(deliverypad).toFixed(2)}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#menu_Pickup">Pickup</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " data-bs-toggle="tab" href="#menu_Manifest">Manifest</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#menu_Delivery">Delivery</a>
                            </li>
                        </ul>
                        <div className="tab-content customer_boxs">
                            <div className="tab-pane container active" id="menu_Pickup">
                                <section className="data_table">
                                    <div className="row mt-4 justify-content-between">
                                        <div className="col-12 col-md-4">
                                            <h6>Transaction History</h6>
                                        </div>
                                        <div className="col-12 col-md-4 text-end">
                                            <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Send_Pickup_payments"><i className="bx bx-bulb"></i>Generate Payout</a>
                                        </div>
                                    </div>
                                    
                                    <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>					
                                                    <th className="border-gray-200">#</th>
                                                    <th className="border-gray-200">Payment Date</th>
                                                    <th className="border-gray-200">Shipment No</th>
                                                    <th className="border-gray-200">Paid Amount</th>
                                                    <th className="border-gray-200">Transaction Number</th>
                                                    <th className="border-gray-200">Payment Mode</th>
                                                </tr>
                                            </thead>
                                            {allpickuppadtransaction.length>0 ?
                                            <tbody>
                                                {allpickuppadtransaction.map((item,index)=>{
                                                    return(
                                                        <tr>
                                                            <td>
                                                                {index+1}
                                                            </td>
                                                            <td><span className="fw-normal text-capitalize">{item.payment_date}</span></td>
                                                            <td><span className="fw-normal text-capitalize">{item.slip_no}</span></td>
                                                            <td><span className="fw-normal text-capitalize">{getDefaultCurrency()}{item.commission_price}</span></td>
                                                            <td><span className="fw-normal text-capitalize">{item.transaction_id}</span></td>
                                                            <td><span className="fw-normal text-capitalize">{item.payment_mode}</span></td>
                                                        </tr>                   
                                                    );
                                                })}
                                            </tbody>
                                            :
                                                <tbody>
                                                    <tr><td rowSpan={6} className="text-danger">Record Not Found</td></tr>
                                                </tbody>
                                            }
                                        </table>
                                        
                                    </div>
                                </section>
                            </div>
                            <div className="tab-pane container fade" id="menu_Manifest">
                                <section className="data_table">
                                        <div className="row mt-4 justify-content-between">
                                            <div className="col-12 col-md-4">
                                                <h6>Transaction History</h6>
                                            </div>
                                            <div className="col-12 col-md-4 text-end">
                                                <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Send_Manifest_payments"><i className="bx bx-bulb"></i>Generate Payout</a>
                                            </div>
                                        </div>
                                        
                                        <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>					
                                                        <th className="border-gray-200">#</th>
                                                        <th className="border-gray-200">Payment Date</th>
                                                        <th className="border-gray-200">Shipment No</th>
                                                        <th className="border-gray-200">Paid Amount</th>
                                                        <th className="border-gray-200">Transaction Number</th>
                                                        <th className="border-gray-200">Payment Mode</th>
                                                    </tr>
                                                </thead>
                                                {allmanifestpadtransaction.length>0 ?
                                                <tbody>
                                                    {allmanifestpadtransaction.map((item,index)=>{
                                                        return(
                                                            <tr>
                                                                <td>
                                                                    {index+1}
                                                                </td>
                                                                <td><span className="fw-normal text-capitalize">{item.payment_date}</span></td>
                                                                <td><span className="fw-normal text-capitalize">{item.slip_no}</span></td>
                                                                <td><span className="fw-normal text-capitalize">{getDefaultCurrency()}{item.commission_price}</span></td>
                                                                <td><span className="fw-normal text-capitalize">{item.transaction_id}</span></td>
                                                                <td><span className="fw-normal text-capitalize">{item.payment_mode}</span></td>
                                                            </tr>                   
                                                        );
                                                    })}
                                                </tbody>
                                                :
                                                    <tbody>
                                                        <tr><td rowSpan={6} className="text-danger">Record Not Found</td></tr>
                                                    </tbody>
                                                }
                                            </table>
                                            
                                        </div>
                                </section>
                            </div>
                            <div className="tab-pane container fade" id="menu_Delivery">
                                <section className="data_table">
                                        <div className="row mt-4 justify-content-between">
                                            <div className="col-12 col-md-4">
                                                <h6>Transaction History</h6>
                                            </div>
                                            <div className="col-12 col-md-4 text-end">
                                                <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Send_Delivery_payments"><i className="bx bx-bulb"></i>Generate Payout</a>
                                            </div>
                                        </div>
                                        
                                        <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>					
                                                        <th className="border-gray-200">#</th>
                                                        <th className="border-gray-200">Payment Date</th>
                                                        <th className="border-gray-200">Shipment No</th>
                                                        <th className="border-gray-200">Paid Amount</th>
                                                        <th className="border-gray-200">Transaction Number</th>
                                                        <th className="border-gray-200">Payment Mode</th>
                                                    </tr>
                                                </thead>
                                                {alldeliverypadtransaction.length>0 ?
                                                <tbody>
                                                    {alldeliverypadtransaction.map((item,index)=>{
                                                        return(
                                                            <tr>
                                                                <td>
                                                                    {index+1}
                                                                </td>
                                                                <td><span className="fw-normal text-capitalize">{item.payment_date}</span></td>
                                                                <td><span className="fw-normal text-capitalize">{item.slip_no}</span></td>
                                                                <td><span className="fw-normal text-capitalize">{getDefaultCurrency()}{item.commission_price}</span></td>
                                                                <td><span className="fw-normal text-capitalize">{item.transaction_id}</span></td>
                                                                <td><span className="fw-normal text-capitalize">{item.payment_mode}</span></td>
                                                            </tr>                   
                                                        );
                                                    })}
                                                </tbody>
                                                :
                                                    <tbody>
                                                        <tr><td rowSpan={6} className="text-danger">Record Not Found</td></tr>
                                                    </tbody>
                                                }
                                            </table>
                                            
                                        </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }
            <Footer/>
            <div class="modal fade" id="Send_Pickup_payments">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Generate Payout</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <div class="modal-body">
                        <div class="row mt-3">
                            <div class="col-12 col-md">
                                <div class="row">
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="email" class="form-label">Payment Received Method:</label>
                                        <select class="form-select" onChange={(e)=>setMethod(e.target.value)}>
                                        <option value="Cash">Cash</option>
                                        {/* <option value="Wallet">Wallet</option> */}
                                        <option value="Bank Transfer">Bank Transfer</option>
                                        <option value="Cheque Deposit">Cheque Deposit</option>
                                        <option value="Online Payment Gateawy">Online Payment Gateawy</option>
                                        <option value="Credit Card">Credit Card</option>
                                        <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="email" class="form-label">Reference no#:</label>
                                        <input type="text" class="form-control" value={RefrenceNo} onChange={(e)=>setRefrenceNo(e.target.value)} placeholder="Enter Reference number"/>
                                    </div>
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="email" class="form-label">Remarks/NOTE:</label>
                                        <input type="text" class="form-control" value={Remark} onChange={(e)=>setRemark(e.target.value)} placeholder="Enter Remarks/NOTE"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <section class="pt-4">
                            <h6>Payment List:</h6>
                            <table class="table table-striped table-hover">
                                <thead class="table-dark">
                                <tr>
                                    <th>
                                        <input className="form-check-input" type="checkbox" onChange={handleSelectAllChange}  checked={pickupcheckedItems.length === allpickupunpadtransaction.length}/>
                                    </th>
                                    <th>#</th>
                                    <th>Entry Date</th>
                                    <th>Shipment No</th>
                                    <th>Amount</th>
                                </tr>
                                </thead>
                                {allpickupunpadtransaction.length>0?
                                <tbody>
                                    {allpickupunpadtransaction.map((Inv,index)=>{
                                        return(
                                            <tr key={index}>
                                                <td style={{"width": "20px"}}>
                                                    <input className="form-check-input" type="checkbox" value={Inv.slip_no} checked={isChecked(Inv.slip_no)} onChange={handleChange} />
                                                </td>
                                                <td>{index+1}</td>
                                                <td>{Inv.date}</td>
                                                <td>{Inv.slip_no}</td>
                                                <td>{Inv.commission_price}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                :
                                <tbody><tr><td colSpan={6}>Record Not Found</td></tr></tbody>
                                }
                            </table>
                        </section>

                        

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" onClick={()=>handleSubmit('Pickup')}>Generate Payout</button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>

                    </div>
                </div>
            </div>

            <div class="modal fade" id="Send_Manifest_payments">
                <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Generate Payout</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                        </div>

                        <div class="modal-body">
                            <div class="row mt-3">
                                <div class="col-12 col-md">
                                    <div class="row">
                                        <div class="col-12 col-md-6 mb-3">
                                            <label for="email" class="form-label">Payment Received Method:</label>
                                            <select class="form-select" onChange={(e)=>setManifestMethod(e.target.value)}>
                                            <option value="Cash">Cash</option>
                                            {/* <option value="Wallet">Wallet</option> */}
                                            <option value="Bank Transfer">Bank Transfer</option>
                                            <option value="Cheque Deposit">Cheque Deposit</option>
                                            <option value="Online Payment Gateawy">Online Payment Gateawy</option>
                                            <option value="Credit Card">Credit Card</option>
                                            <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        <div class="col-12 col-md-6 mb-3">
                                            <label for="email" class="form-label">Reference no#:</label>
                                            <input type="text" class="form-control" value={manifestRefrenceNo} onChange={(e)=>setManifestRefrenceNo(e.target.value)} placeholder="Enter Reference number"/>
                                        </div>
                                        <div class="col-12 col-md-12 mb-3">
                                            <label for="email" class="form-label">Remarks/NOTE:</label>
                                            <input type="text" class="form-control" value={manifestRemark} onChange={(e)=>setManifestRemark(e.target.value)} placeholder="Enter Remarks/NOTE"/>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <hr/>
                            <section class="pt-4">
                                <h6>Payment List:</h6>
                                <table class="table table-striped table-hover">
                                    <thead class="table-dark">
                                    <tr>
                                        <th>
                                            <input className="form-check-input" type="checkbox" onChange={handlemanifestSelectAllChange}  checked={manifestcheckedItems.length === allmanifestunpadtransaction.length}/>
                                        </th>
                                        <th>#</th>
                                        <th>Entry Date</th>
                                        <th>Shipment No</th>
                                        <th>Amount</th>
                                    </tr>
                                    </thead>
                                    {allmanifestunpadtransaction.length>0?
                                    <tbody>
                                        {allmanifestunpadtransaction.map((Inv,index)=>{
                                            return(
                                                <tr key={index}>
                                                    <td style={{"width": "20px"}}>
                                                        <input className="form-check-input" type="checkbox" value={Inv.slip_no} checked={manifestisChecked(Inv.slip_no)} onChange={handlemanifestChange} />
                                                    </td>
                                                    <td>{index+1}</td>
                                                    <td>{Inv.date}</td>
                                                    <td>{Inv.slip_no}</td>
                                                    <td>{Inv.commission_price}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                    :
                                    <tbody><tr><td colSpan={6}>Record Not Found</td></tr></tbody>
                                    }
                                </table>
                            </section>

                            

                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" onClick={()=>handleSubmit('Manifest')}>Generate Payout</button>
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                        </div>

                        </div>
                </div>
            </div>

            <div class="modal fade" id="Send_Delivery_payments">
                <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">Generate Payout</h4>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                            </div>

                            <div class="modal-body">
                                <div class="row mt-3">
                                    <div class="col-12 col-md">
                                        <div class="row">
                                            <div class="col-12 col-md-6 mb-3">
                                                <label for="email" class="form-label">Payment Received Method:</label>
                                                <select class="form-select" onChange={(e)=>setDeliveryMethod(e.target.value)}>
                                                <option value="Cash">Cash</option>
                                                {/* <option value="Wallet">Wallet</option> */}
                                                <option value="Bank Transfer">Bank Transfer</option>
                                                <option value="Cheque Deposit">Cheque Deposit</option>
                                                <option value="Online Payment Gateawy">Online Payment Gateawy</option>
                                                <option value="Credit Card">Credit Card</option>
                                                <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-md-6 mb-3">
                                                <label for="email" class="form-label">Reference no#:</label>
                                                <input type="text" class="form-control" value={deliveryRefrenceNo} onChange={(e)=>setDeliveryRefrenceNo(e.target.value)} placeholder="Enter Reference number"/>
                                            </div>
                                            <div class="col-12 col-md-12 mb-3">
                                                <label for="email" class="form-label">Remarks/NOTE:</label>
                                                <input type="text" class="form-control" value={deliveryRemark} onChange={(e)=>setDeliveryRemark(e.target.value)} placeholder="Enter Remarks/NOTE"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <section class="pt-4">
                                    <h6>Payment List:</h6>
                                    <table class="table table-striped table-hover">
                                        <thead class="table-dark">
                                        <tr>
                                            <th>
                                                <input className="form-check-input" type="checkbox" onChange={handledeliverySelectAllChange}  checked={deliverycheckedItems.length === alldeliveryunpadtransaction.length}/>
                                            </th>
                                            <th>#</th>
                                            <th>Entry Date</th>
                                            <th>Shipment No</th>
                                            <th>Amount</th>
                                        </tr>
                                        </thead>
                                        {alldeliveryunpadtransaction.length>0?
                                        <tbody>
                                            {alldeliveryunpadtransaction.map((Inv,index)=>{
                                                return(
                                                    <tr key={index}>
                                                        <td style={{"width": "20px"}}>
                                                            <input className="form-check-input" type="checkbox" value={Inv.slip_no} checked={deliveryisChecked(Inv.slip_no)} onChange={handledeliveryChange} />
                                                        </td>
                                                        <td>{index+1}</td>
                                                        <td>{Inv.date}</td>
                                                        <td>{Inv.slip_no}</td>
                                                        <td>{Inv.commission_price}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                        :
                                        <tbody><tr><td colSpan={6}>Record Not Found</td></tr></tbody>
                                        }
                                    </table>
                                </section>

                                

                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" onClick={()=>handleSubmit('Delivery')}>Generate Payout</button>
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                            </div>

                            </div>
                </div>
            </div>
        </main>
        </>

    );
}
export default HubPayout;