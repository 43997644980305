import { useState, useEffect } from 'react';
import Header from './include/header';
import Footer from './include/footer';
import LeftPanel from './include/leftpanel';
import ShipmentChart from './Charts/ShipmentChart';
import SaleChart from './Charts/SaleChart';
import ShipmentDetailChart from './Charts/ShipmentDetailChart';
import PickAndDelChart from './Charts/PickAndDelCharts';
import TopCustChart from './Charts/TopCustChart';
import TopCityChart from './Charts/TopCityChart';
import TopShippingChart from './Charts/TopShippingChart';
import ProductChart from './Charts/ProductChart';
import TopHubChart from './Charts/TopHubChart';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Global from '../APIConfig';
import  axios from "axios";
import AuthUser from "../AuthUser";

function AdminDashboard() {
    let navigate = useNavigate();
    const{headers1,logout}=AuthUser();
const [trackingnumber, setTrackingnumber] = useState('');
const [totalcustomer, setTotalcustomer] = useState('');
const [totalshipment, setTotalshipment] = useState('');
const [totalrevenue, setTotalrevenue] = useState('');

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('userdata'));
        if (items) {
        //  alert(items.name);
        }
        Get30DaysCustomers();
        Get30DaysShipments();
        Get30DaysRevenue();
      }, []);
      const Trackingshipment=()=>{
        if(trackingnumber !="")
        {
            navigate(`/ShipmentTracking/${trackingnumber}`);
        }
        else
        {
            toast.warn("Please Enter AWB No.");
        }
      }
      const Get30DaysCustomers=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Get30DaysCustomers',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data;
                setTotalcustomer(data.label);
                
            }
            else
            {
                setTotalcustomer(0);
                
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }
    const Get30DaysShipments=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Get30DaysShipments',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data;
                setTotalshipment(data.label);
                
            }
            else
            {
                setTotalshipment(0);
                
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }
    const Get30DaysRevenue=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Get30DaysRevenue',
            headers:headers1,
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data;
                setTotalrevenue(data.label);
                
            }
            else
            {
                setTotalrevenue(0);
                
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }
  return (
    <>
        <LeftPanel/>
        <main className='home-section'>
        <Header/>
        <ToastContainer/>
        <div className="overlay"></div>
            <div className="py-4 new_request_menu">
                <div className="dropdown">
                    <button className="btn btn-gray-800 d-inline-flex align-items-center me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                        New Request
                    </button>
                    <div className="dropdown-menu p-0">
                        <div className="card">
                          <div className="card-body">
                            <h4>Add Shipment</h4>
                            <small>Add a Single shipment or choose to add multiple shipment in bulk through CSV or excel sheet upload</small>
                            <div className="row mt-3">
                                <div className="col-12 col-md-4">
                                    <div className="d-flex ">
                                        <div className="p-1">
                                            <i className="bx bx-cube"></i>
                                        </div>
                                        <div className="p-1 ">
                                            <a href="/Createshipment">Add Shipment</a>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-12 col-md-4">
                                    <div className="d-flex ">
                                        <div className="p-1">
                                            <i className="bx bx-copy"></i>
                                        </div>
                                        <div className="p-1 ">
                                            <a href="/Createshipment">Multiple Shipment</a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <hr />
                            <h4>Add Pickup</h4>
                            <div className="row justify-content-between">
                                <div className="col-12 col-md-4">
                                    <div className="d-flex ">
                                        <div className="p-1">
                                            <i className="bx bx-collection"></i>
                                        </div>
                                        <div className="p-1 ">
                                            <a href="/Generatepickup">Generate PRS</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md">
                                    <div className="d-flex ">
                                        <div className="p-1">
                                            <i className="bx bx-time"></i>
                                        </div>
                                        <div className="p-1 ">
                                            <a href="">Schedule Customer PRS</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <h4>Add Manifest</h4>
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="d-flex ">
                                        <div className="p-1">
                                            <i className="bx bx-cube-alt"></i>
                                        </div>
                                        <div className="p-1 ">
                                            <a href="/AddBag">Generate New Bag</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md">
                                    <div className="d-flex ">
                                        <div className="p-1">
                                            <i className="bx bx-cube-alt"></i>
                                        </div>
                                        <div className="p-1 ">
                                            <a href="/AddBagManifest">Generate Bag Manifest</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <h4>Add Delivery Sheet</h4>
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="d-flex ">
                                        <div className="p-1">
                                            <i className="bx bx-run"></i>
                                        </div>
                                        <div className="p-1 ">
                                            <a href="/AddDrs">Generate DRS</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <h4>Track Shipment</h4>
                            <div className="row ">
                                <div className="col-12 col-md">
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1">
                                        <svg className="icon icon-xxs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                        </span>
                                        <input type="text" className="form-control" id="exampleInputIconLeft" placeholder="Search" aria-label="Search" onChange={(e)=>setTrackingnumber(e.target.value)} />
                                        <button className="btn btn-primary text-light" onClick={()=>Trackingshipment()}>Track</button>
                                    </div>
                                </div>
                            </div>

                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row dash_state_box">
                <div className="col-12 col-sm-6 col-xl-4 mb-4">
                    <div className="card border-0 shadow">
                        <div className="card-body">
                            <div className="row d-block d-xl-flex align-items-center">
                                <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                    <div className="icon-shape icon-shape-default rounded me-4 me-sm-0">
                                        <i className="bx bx-package"></i>
                                    </div>
                                    <div className="d-sm-none">
                                        <h2 className="fw-extrabold h5"> Shipments</h2>
                                        <h3 className="mb-1">{totalshipment}</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-7 px-xl-0">
                                    <div className="d-none d-sm-block">
                                        <h2 className=""> Shipments</h2>
                                        <h3 className="">{totalshipment}</h3>
                                    </div>
                                    <small className="">
                                        Last 30 days
                                    </small> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-4 mb-4">
                    <div className="card border-0 shadow">
                        <div className="card-body">
                            <div className="row d-block d-xl-flex align-items-center">
                                <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                    <div className="icon-shape icon-shape-default rounded me-4 me-sm-0">
                                        <i className="bx bx-user"></i>
                                    </div>
                                    <div className="d-sm-none">
                                        <h2 className="fw-extrabold h5"> Customers</h2>
                                        <h3 className="mb-1">{totalcustomer}</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-7 px-xl-0">
                                    <div className="d-none d-sm-block">
                                        <h2 className=""> Customers</h2>
                                        <h3 className="">{totalcustomer}</h3>
                                    </div>
                                    <small className="">
                                        Last 30 days
                                    </small> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-4 mb-4">
                    <div className="card border-0 shadow">
                        <div className="card-body">
                            <div className="row d-block d-xl-flex align-items-center">
                                <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                    <div className="icon-shape icon-shape-default rounded me-4 me-sm-0">
                                        <i className="bx bx-money-withdraw"></i>
                                    </div>
                                    <div className="d-sm-none">
                                        <h2 className="fw-extrabold h5"> Revenue</h2>
                                        <h3 className="mb-1">{Number(totalrevenue).toFixed(2)}</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-7 px-xl-0">
                                    <div className="d-none d-sm-block">
                                        <h2 className=""> Revenue</h2>
                                        <h3 className="">{Number(totalrevenue).toFixed(2)}</h3>
                                    </div>
                                    <small className="">
                                        Last 30 days
                                    </small> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="mb-3">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="shipment_card_st shadow-sm">
                            <ShipmentChart/>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="sales_card_st shadow-sm">
                            <SaleChart/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-3">
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="card  border-0 shadow-sm">
                        <div className="card-body">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <h6>Shipments</h6>
                                    </div>
                                    <div className="">
                                        <small>Last 30 days</small>
                                    </div>
                                </div>
                                <div className="row">
                                    <ShipmentDetailChart/>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mb-3">
                <div className="row">
                    <div className="col-12 col-md-12 mb-3">
                        <div className="card border-0 shadow-sm">
                          <div className="card-body">
                            <h5>Shipment Pickup & Delivery</h5>
                            <small className="">Number of shipment Pickedup & Delivered in last 30 days</small>
                            <PickAndDelChart/>
                          </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="card border-0 shadow-sm">
                          <div className="card-body">
                            <h5>Top Customer</h5>
                            <small>Top 5 customers by shipment booking in last 30 days</small>
                            <TopCustChart/>
                          </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="card border-0 shadow-sm">
                          <div className="card-body">
                            <h5>Top City</h5>
                            <small>Top 5 cities by shipment booking in last 30 days</small>
                            <TopCityChart/>
                          </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="card border-0 shadow-sm">
                          <div className="card-body">
                            <h5>Top HUB</h5>
                            <small>Top 5 HUB by shipment booking in last 30 days</small>
                            <TopHubChart />
                          </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="card border-0 shadow-sm">
                          <div className="card-body">
                            <h5>Top Shipping Service</h5>
                            <small>Find out best shipping service by booking in last 30 days</small>
                            <TopShippingChart/>
                          </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="card border-0 shadow-sm">
                          <div className="card-body">
                            <h5>Product Type</h5>
                            <small>Get the best Product type by shipment booking in last 30 days</small>
                            <ProductChart/>
                          </div>
                        </div>
                    </div>
                    {/* <div className="col-12 col-md-4 mb-3">
                        <div className="card border-0 shadow-sm">
                          <div className="card-body">
                            <h5>Recent Support Tickets</h5>
                            <small>Get the best Product type by shipment booking in last 30 days</small>
                            <ul className="list-group mt-3">
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <a href="">
                                        Ticket #919928
                                        <h6>Please update the shipment status</h6>
                                    </a>
                                    <span className="badge bg-primary rounded-pill">Open</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <a href="">
                                        Ticket #919928
                                        <h6>Please update the shipment status</h6>
                                    </a>
                                    <span className="badge bg-danger rounded-pill">Closed</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <a href="">
                                        Ticket #919928
                                        <h6>Please update the shipment status</h6>
                                    </a>
                                    <span className="badge bg-warning rounded-pill">Pending</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <a href="">
                                        Ticket #919928
                                        <h6>Please update the shipment status</h6>
                                    </a>
                                    <span className="badge bg-primary rounded-pill">Open</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <a href="">
                                        Ticket #919928
                                        <h6>Please update the shipment status</h6>
                                    </a>
                                    <span className="badge bg-primary rounded-pill">Open</span>
                                </li>
                            </ul>
                          </div>
                        </div>
                    </div> */}
                </div>
            </section>
            <Footer/>
            </main>
    </>
  );
}

export default AdminDashboard;