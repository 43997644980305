import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import LeftPanel from "../include/leftpanel";
import axios from "axios";
import Global from "../../APIConfig";
import { Link, useNavigate,useParams } from "react-router-dom";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainLoader from "../Loaders/MainLoader";
import CopyToClipboard from 'react-copy-to-clipboard';

function PickupDetails(){
    let navigate = useNavigate();
    const { drsid } =useParams();
    const [prsdetails, setPrsdetails] = useState([]);
    const [shipment, setShipment] = useState([]);
    const{headers1,headers2,logout}=AuthUser();
    const [Loading, setLoading] = useState(true);
    useEffect(()=>{
        GetPickupDetails();
    },[]);

    const GetPickupDetails=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'PickupDetail',
            headers:headers1,
            data: {
                drs_unique_id: drsid
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var prsdata=response.data.value;
                var shipmentdata = response.data.value.Totalshipment;
                setPrsdetails(prsdata);
                setShipment(shipmentdata);
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }
    return(
        <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true?<MainLoader/>
                :
                <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Pick Run Sheet</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">PRS #{drsid}</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="data_table">
                    {prsdetails.delivered =='N'?
                        <span className="status pending"> <i className="bx bx-time" aria-hidden="true"></i> Pending</span>
                        :
                        <span class="status completed"> <i class="bx bx-check-double"></i> Completed</span>
                        }
                    </div>
                    {/* <div className="btn-group ms-2 ms-lg-3">
                        <button type="button" className="btn btn-sm btn-outline-gray-700" data-bs-toggle="tooltip" aria-label="Print PRS" data-bs-original-title="Print PRS"><i className="bx bx-printer"></i> </button>
                        <button type="button" className="btn btn-sm btn-outline-gray-700" data-bs-toggle="tooltip" aria-label="Update Status" data-bs-original-title="Update Status"><i className="bx bx-revision"></i> </button>
                    </div> */}
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-12">
                    <div className="card mb-3">
                      <div className="card-body">
                        <h5>PRS Details</h5>
                        <hr />
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <small className="text-muted">Assigned to Driver</small>
                                <p><strong>{prsdetails.messenger_name}</strong></p>       
                            </div>
                            <div className="col-12 col-md-4">
                                <small className="text-muted">Route Code</small>
                                <p><strong>{prsdetails.routecode}</strong></p>
                            </div>
                            <div className="col-12 col-md-4">
                                <small className="text-muted">CITY</small>
                                <p><strong>{prsdetails.city}, {prsdetails.country}</strong></p>
                            </div>
                            <div className="col-12 col-md-4">
                                <small className="text-muted">Total Weight</small>
                                <p><strong>{prsdetails.shipmentweight}KG</strong></p>
                            </div>
                            <div className="col-12 col-md-4">
                                <small className="text-muted">Total Shipments</small>
                                <p><strong>{prsdetails.Totalpickupshipment} <small>/{prsdetails.Totalshipmentcount}</small></strong></p>  
                            </div>
                            <div className="col-12 col-md-4">
                                <small className="text-muted">Number of Prepaid</small>
                                <p><strong>{prsdetails.shipmentPrepaidcount}</strong></p>
                            </div>
                            <div className="col-12 col-md-4">
                                <small className="text-muted">Number of COD</small>
                                <p><strong>{prsdetails.shipmentcodcount}</strong></p>
                            </div>
                            <div className="col-12 col-md-4">
                                <small className="text-muted">Total COD Value</small>
                                <p><strong>${prsdetails.shipmentcodamount}</strong></p>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            
            

            <div className="row g-1">
                <div className="col-12 col-md">
                    <section className="data_table">
                        <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                            <table className="table table-hover">
                                <thead>
                                    <tr>						
                                        <th className="border-gray-200">AWB #</th>
                                        <th className="border-gray-200">Product Type</th>
                                        <th className="border-gray-200">Weight</th>
                                        <th className="border-gray-200">Dimension</th>
                                        <th className="border-gray-200">Value</th>
                                        <th className="border-gray-200">Address</th>
                                        <th className="border-gray-200">Mode</th>
                                        <th className="border-gray-200">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {shipment.map((ship,index)=>{
                                        return(
                                        <tr key={index}>
                                            <td>
                                                <div>
                                                    <Link target="_blank" to={`/ShipmentDetails/${ship.slip_no}`} className="fw-bold">{ship.slip_no}</Link>
                                                    <CopyToClipboard text={ship.slip_no} onCopy={()=>{toast.success(ship.slip_no+' Copied To Clipboard')}}>
                                                        <i className="bx bx-copy"></i>
                                                    </CopyToClipboard>
                                                </div>
                                            </td>
                                            <td><span className="fw-normal text-capitalize">{ship.nrd}</span></td>
                                            <td><span className="fw-normal text-capitalize">{ship.volumetric_weight}KG</span></td>
                                            <td><span className="fw-normal text-capitalize">{ship.height}x{ship.width}x{ship.length}</span></td>
                                            <td><span className="fw-normal text-capitalize">${ship.total_amt}</span></td>
                                            <td><span className="fw-normal text-capitalize">{ship.reciever_name} 
                                                <div>{ship.reciever_address}</div>
                                            </span></td>
                                            <td><span className="fw-normal text-capitalize"><span className="badge bg-secondary">{ship.booking_mode}</span> <br />{ship.booking_mode =='COD' && ship.total_cod_amt}</span></td>
                                            <td>
                                                {ship.delivered !== 'T'?
                                                <span className="status pickup_awit"> <i className="fa fa-clock-o" aria-hidden="true"></i> Proceed For Pickup</span>
                                                :
                                                <span class="status completed"> <i class="bx bx-check-double"></i> Completed</span>
                                                }
                                            </td>
                                        </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </div>
            </>
            }
            <Footer/>
        </main>
    </>
    );
}
export default PickupDetails;