import React, {useState, useEffect} from "react";
import Header from "../include/header";
import Footer from "../include/footer";
import UserLeftPanel from "../include/UserLeftPanel";
import Global from "../../APIConfig";
import  axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from "../../AuthUser";
import { useNavigate,useParams } from 'react-router-dom';
import MainLoader from "../Loaders/MainLoader";

function HubEdit(){
    let navigate = useNavigate();
    const {UniqueId} = useParams();
    const {headers1,headers2,logout}=AuthUser();
    const [Countrylist, setCountrylist] = useState([]);
    const [Citylist, setCitylist] = useState([]);
    const [Name, setName] = useState('');
    const [HubName, setHubName] = useState('');
    const [Phone, setPhone] = useState('');
    const [Email, setEmail] = useState('');
    const [Zip, setZip] = useState('');
    const [Country, setCountry] = useState('');
    const [City, setCity] = useState('');
    const [Address, setAddress] = useState('');
    const [Loading, setLoading] = useState(true);
    const [BtnLoading, setBtnLoading] = useState(false);
    const [Password, setPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [profileimg, setProfileimg] = useState('');
    const [oldprofileimg, setOldProfileimg] = useState('');

    useEffect(()=>{
        GetHubDetail(UniqueId);
        GetCountrylist();
    },[]);
    const GetHubDetail=(id)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'GetHubDetail',
            headers:headers1,
            data: {
                id:id
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setName(data.name);
                setHubName(data.hub_name);
                setAddress(data.address);
                setPhone(data.mobile);
                setZip(data.pin_code);
                setEmail(data.email);
                setCity(data.branch_location);
                setCountry(data.country);
                handleCountryChange(data.country);
                setOldProfileimg(data.profile_image);
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
          });
    }
    const GetCountrylist=()=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Countrylist',
            data: {
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCountrylist(data);
            }
          })
          .catch(function (error) {
            setLoading(false);
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
          });
    }
    const GetCityList=(val)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'Citylist',
            data: {
                name: val
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setCitylist(data);
            }
          })
          .catch(function (error) {
            setLoading(false);
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
          });
    }
    const handleCountryChange = (val) => {
        setCountry(val);
        GetCityList(val);
    };
    const handleCityChange = (val) => {
        setCity(val);
    };
    const handleSubmit=()=>{
        if(Name==""){
            toast.warning('Name is Required');
        }
        else if(HubName==""){
            toast.warning('Hub Name is Required');
        }
        else if(Phone==""){
            toast.warning('Mobile No. is Required');
        }
        else if(Email==""){
            toast.warning('Email is Required');
        }
        else if(Zip==""){
            toast.warning('Postal Code is Required');
        }
        else if(Country==""){
            toast.warning('Please Select Country');
        }
        else if(City==""){
            toast.warning('Please Select City');
        }
        else if(Address==""){
            toast.warning('Address is Required');
        }
        else{
            setBtnLoading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'EditHub',
                headers:headers2,
                data: {
                    UniqueId:UniqueId,
                    Name:Name,
                    HubName:HubName,
                    Phone:Phone,
                    Email:Email,
                    Zip:Zip,
                    Country:Country,
                    City:City,
                    Address:Address,
                    profile_image:profileimg,
                    oldprofile_image:oldprofileimg
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate('/HubList');
                      }, 1000);
                }else{
                    toast.warning(response.data.message);
                }
                setBtnLoading(false);
              })
              .catch(function (error) {
                setBtnLoading(false);
                setLoading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
              });
        }
    }
    const handlePassSubmit=()=>{
        if(Password==""){
            toast.warning('Password is Required');
        }
        else if(ConfirmPassword==""){
            toast.warning('Confirm Password is Required');
        }
        else if(Password!=ConfirmPassword){
            toast.warning('Password And Confirm Password Must Be Same');
        }
        else{
            setBtnLoading(true);
            axios({
                method: 'post',
                url: Global.baseUrl+'HubChangePassword',
                headers:headers1,
                data: {
                    UniqueId:UniqueId,
                    Password:Password
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate('/HubList');
                      }, 1000);
                }else{
                    toast.warning(response.data.message);
                }
                setBtnLoading(false);
              })
              .catch(function (error) {
                setLoading(false);
                setBtnLoading(false);
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
              });
        }
    }

    const handelpostalcode=(zipcode)=>{
        axios({
            method: 'post',
            url: Global.baseUrl+'countrydatabypincode',
            headers:headers1,
            data: {
                Postalcode: zipcode,
            }
        })
        .then(function (response) {
            if(response.data.status == 200)
            {
                GetCityList(response.data.value.country)
                setCountry(response.data.value.country);
                setCity(response.data.value.id);
            }
            else
            {
                GetCityList('');
                setCountry('');
                setCity('');
            }
        })
        .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
        });
        }
    return(
        <>
            <UserLeftPanel/>
            <main className='home-section'>
                <Header/>
                <ToastContainer/>
                {Loading==true?<MainLoader/>:
                <>
                <section className="left_sub_menu">
                    <div className="row">
                        <div className="col-12 col-md">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                                <div className="d-block mb-4 mb-md-0">
                                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                            <li className="breadcrumb-item">
                                                <a href="/Admin-Dash">
                                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                                </a>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">Edit Hub</li>
                                        </ol>
                                    </nav>
                                    <div className="d-flex justify-content-between">
                                        <div className="">
                                            <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                                            <i className="bx bx-arrow-back "></i>
                                            </button>
                                        </div>
                                        <div className="ps-2 ">
                                            <h2 className="h4">Edit Hub</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                <h6>Edit Hub</h6>
                                    <div className="row">
                                        <div className="col-12 mb-3 mt-4">
                                            <div className="row">
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Name: <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" value={Name} onChange={(e)=>setName(e.target.value)} placeholder="Please Enter Name" />
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Hub Name: <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" value={HubName} onChange={(e)=>setHubName(e.target.value)} placeholder="Please Enter Hub Name" />
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Mobile No.: <span className="text-danger">*</span></label>
                                                    <input type="number" className="form-control" value={Phone} onChange={(e)=>setPhone(e.target.value)} placeholder="Please Enter Mobile No." />
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Email: <span className="text-danger">*</span></label>
                                                    <input type="email" className="form-control" value={Email} onChange={(e)=>setEmail(e.target.value)} placeholder="Please Enter Email" />
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Postal Code: <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" value={Zip} onChange={(e)=>{setZip(e.target.value);handelpostalcode(e.target.value)}} placeholder="Please Enter Postal Code" />
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <div><label for="email" className="form-label">Country: <span className="text-danger">*</span></label></div>
                                                    <select className="form-select" onChange={(e)=>handleCountryChange(e.target.value)}>
                                                        <option value="">-- Please Select --</option>
                                                        {Countrylist.map((list)=>{
                                                            return (
                                                                <option selected={list.country==Country?true:false} value={list.country}>{list.country}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <div><label for="email" className="form-label">City: <span className="text-danger">*</span></label></div>
                                                    <select className="form-select" onChange={(e)=>handleCityChange(e.target.value)}>
                                                        <option value="">-- Please Select --</option>
                                                        {Citylist.map((list)=>{
                                                            return (
                                                                <option selected={list.value==City?true:false} value={list.value}>{list.label}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col-4 mb-3">
                                                    <label for="" className="form-label">Image: <span className="text-danger">*</span></label>
                                                    <input type="file" className="form-control" onChange={(e)=>setProfileimg(e.target.files[0])} placeholder="Please Select File" />
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <label for="" className="form-label">Address: <span className="text-danger">*</span></label>
                                                    <textarea className="form-control" value={Address} rows={5} onChange={(e)=>setAddress(e.target.value)} placeholder="Please Enter Hub Address" ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {BtnLoading==false?
                                    <div className="d-grid col-md-2 mt-2">
                                        <button className="btn btn-primary" onClick={()=>handleSubmit()}>Submit</button>
                                    </div>
                                    :
                                    <div className="d-grid col-md-2 mt-2">
                                        <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                                    </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="left_sub_menu pt-2">
                    <div className="card">
                        <div className="card-body">
                            <h6>Change Hub Password</h6>
                            <div className="row">
                                <div className="col-12 mb-3 mt-4">
                                    <div className="row">
                                        <div className="col-4 mb-3">
                                            <label for="" className="form-label">Password: <span className="text-danger">*</span></label>
                                            <input type="password" className="form-control" onChange={(e)=>setPassword(e.target.value)} placeholder="Please Enter Password" />
                                        </div>
                                        <div className="col-4 mb-3">
                                            <label for="" className="form-label">Confirm Password: <span className="text-danger">*</span></label>
                                            <input type="password" className="form-control" onChange={(e)=>setConfirmPassword(e.target.value)} placeholder="Please Enter Confirm Password" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {BtnLoading==false?
                            <div className="d-grid col-md-2 mt-2">
                                <button className="btn btn-primary" onClick={()=>handlePassSubmit()}>Submit</button>
                            </div>
                            :
                            <div className="d-grid col-md-2 mt-2">
                                <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
                            </div>
                            }
                        </div>
                    </div>
                </section>
            </>
                }
                <Footer/>
            </main>
        </>
    )
}
export default HubEdit;